import React, { useContext, useEffect, useState } from 'react';
import {
	ContainedButton,
	EditBtn,
} from '../../Styles/Admin Panel Styles/ButtonStyles';
import {
	Input,
	InputWrapper,
	Textarea,
} from '../../Styles/Admin Panel Styles/InputStyles';
import { ReactComponent as UploadIcon } from '../../assets/imgs/Admin Panel/upload_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as BackIcon } from '../../assets/imgs/Admin Panel/arrow_back_ios_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as NextIcon } from '../../assets/imgs/Admin Panel/arrow_forward_ios_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as DoneIcon } from '../../assets/imgs/Admin Panel/done_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as DeleteIcon } from '../../assets/imgs/Admin Panel/delete_forever_FILL0_wght400_GRAD0_opsz24.svg';
import {
	CategoriesWrapper,
	CategoryBlock,
} from '../../Styles/Admin Panel Styles/CategoriesStyles';
import {
	UploadContainer,
	ImagesContainerSm,
	ProjectContainer,
	GridItem,
	ImagesContainerLg,
} from '../../Styles/Admin Panel Styles/Containers';
import { Dropzone, FileItem } from '@dropzone-ui/react';
import {
	createProject,
	CreateProjectGalleryItem,
	DeleteProjectImageFromGallery,
	UpdateGalleryImage,
	UpdateProjectData,
	UploadFile,
} from '../../services/api';
import GridLayout from 'react-grid-layout';
import Loader from './Loader';
import { SERVER_ENDPOINT } from '../../services';
import AppContext from '../../Contexts/AppContext';
import { updateProjectOrder } from '../../services/api';
import { alertPopup } from '../../services/alertPopup';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function ProjectEdit({
	handleOpenModal,
	type,
	handleGetProject,
	handleGetProjects,
}) {
	const {projectsCategories, currentProject, screenSize } =
		useContext(AppContext);
	const [project, setProject] = useState({
		project_title: '',
		project_subtitle: '',
		project_description: '',
	});
	const [currentStep, setCurrentStep] = useState(1);
	const [activeCats, setActiveCats] = useState(
		currentProject?.categories
			? currentProject.categories.map((cat) => {
					return cat.id;
			  })
			: [],
	);
	const [imageFiles, setImageFiles] = useState([]); //blob files to send to server
	const [projectImgs, setProjectImgs] = useState(
		currentProject?.gallery ? currentProject.gallery : [],
	);
	const [layout, setLayout] = useState(
		currentProject?.gallery
			? currentProject.gallery.map((img) => {
					return img.order;
			  })
			: [],
	);
	const [isLoading, setIsLoading] = useState(false);
	const [isUploadVisible, setIsUploadVisible] = useState(false);
	const [projectMainIcon, setProjectMainIcon] = useState(
		currentProject?.project_icon ? currentProject.project_icon : {},
	);
	const [imgsToDelete, setImgsToDelete] = useState([]);

	const handleSetProjectIcon = (event) => {
		setProjectMainIcon(event.target.files[0]);
	};

	const handleRemoveImgFromGallery = (img_id) => {
		const tmp_imgs_to_delete = [...imgsToDelete];
		if (tmp_imgs_to_delete.includes(img_id)) return;
		setImgsToDelete([...tmp_imgs_to_delete, img_id]);
		const new_proj_imgs = projectImgs.filter((img) => img.id !== img_id);
		setProjectImgs(new_proj_imgs);

		const prev_gallery = [...currentProject?.gallery].filter(
			(img) => img.id !== img_id,
		);
		setLayout(
			prev_gallery.map((img) => {
				return img.order;
			}),
		);
	};

	const handleChangeProjectData = (key, val) => {
		let updated_proj = { ...project };
		updated_proj[`${key}`] = val;
		setProject(updated_proj);
	};

	const handleAddCategory = (cat_id) => {
		const updatedActiveCats = [...activeCats];
		if (updatedActiveCats.includes(cat_id)) {
			const index = updatedActiveCats.indexOf(cat_id);
			updatedActiveCats.splice(index, 1);
			setActiveCats(updatedActiveCats);
			return;
		}
		setActiveCats([...activeCats, cat_id]);
	};

	const handleOnChangeFile = (incommingFiles) => {
		const updated_files = incommingFiles.map((file_obj, key) => {
			return {
				...file_obj,
				is_main: false,
				order: {
					i: `${key + 1 + projectImgs.length}`,
					x: key,
					y: 0,
					w: 1,
					h: 1,
				},
			};
		});

		setImageFiles(updated_files);

		let already_uploaded_files = [];

		for (let i = 0; i < projectImgs.length; i++) {
			let already_uploaded_file = updated_files.find(
				(file) => file.id == projectImgs[i].prev_id,
			);
			if (already_uploaded_file)
				already_uploaded_files.push(already_uploaded_file);
		}

		handleShowImgs(
			updated_files.filter(
				(file) => !already_uploaded_files.includes(file),
			),
		);
	};

	const handleOnDeleteFile = (id) => {
		setImageFiles(imageFiles.filter((x) => x.id !== id));
		setProjectImgs(projectImgs.filter((x) => x.prev_id !== id));
	};

	const handleCreateProject = async () => {
		const main_img = projectImgs.find((img) => img.is_main);
		if (projectImgs.length && !main_img) {
			alertPopup('Please set main image', 'error');
			return;
		}

		setIsLoading(true);
		const updated_proj = {
			...project,
			categories: activeCats,
		};

		if (projectMainIcon?.name) {
			const uploaded_icon = await UploadFile(projectMainIcon);
			if (uploaded_icon) {
				updated_proj.project_icon = uploaded_icon.data;
			}
		}

		const proj_res = await createProject(updated_proj);
		if (proj_res.success && proj_res.data) {
			if (imageFiles.length > 0) {
				for (let i = 0; i < imageFiles.length; i++) {
					const image = imageFiles[i];
					const img_data_res = await UploadFile(image.file);
					if (img_data_res && img_data_res.data) {
						const data = {
							project_id: proj_res?.data?.id,
							img_url: img_data_res.data?.file_url,
							is_main: i + 1 === main_img.id ? true : false,
							order: image?.order,
						};
						const image_gallery_res =
							await CreateProjectGalleryItem(data);
					}
				}
			}

			setProject({});
			setImageFiles([]);
			setProjectMainIcon({});
			setCurrentStep(1);
			setActiveCats([]);
			setIsLoading(false);
			handleOpenModal();
			handleGetProjects();
			return;
		}
		alertPopup('Oops, error occured', 'error');
		setIsLoading(false);
		handleOpenModal();
	};

	const handleUpdateProject = async () => {
		let updated_proj = {};
		const main_img = projectImgs.find((img) => img.is_main);
		if (!main_img) {
			alertPopup('Set main img', 'error');
			return;
		}

		setIsLoading(true);
		const proj_data = {
			...project,
			categories: activeCats,
			id: currentProject.id,
		};

		if (projectMainIcon?.name) {
			//means its newly added icon file
			const uploaded_icon = await UploadFile(projectMainIcon);
			if (uploaded_icon) {
				updated_proj.project_icon = uploaded_icon.data;
				proj_data.project_icon = uploaded_icon.data;
			}
		}
		const proj_res = await UpdateProjectData(proj_data);

		if (proj_res.success && proj_res.data) {
			updated_proj = {
				...proj_res.data,
				gallery: [...projectImgs],
			};

			//update order of project in changed categories
			const prev_project_cats = currentProject?.categories?.map((cat) => {
				return cat.id;
			});
			if (
				prev_project_cats &&
				prev_project_cats?.length < activeCats?.length
			) {
				const newly_added_cats = activeCats.filter(
					(value) => !prev_project_cats.includes(value),
				);
				if (newly_added_cats && newly_added_cats.length) {
					for (let i = 0; i < newly_added_cats.length; i++) {
						const data = {
							id: updated_proj.id,
							category: newly_added_cats[i],
						};
						const updated_order = await updateProjectOrder(data);			
					}
				}
			}

			//upload newly added imgs
			if (imageFiles.length > 0) {
				for (let i = 0; i < imageFiles.length; i++) {
					const image = imageFiles[i];
					const img_data_res = await UploadFile(image.file);
					if (img_data_res && img_data_res.data) {
						const data = {
							project_id: proj_res?.data?.id,
							img_url: img_data_res.data?.file_url,
							is_main: i === main_img.id ? true : false,
							order: updated_proj.gallery.find(
								(img) => img.prev_id === image.id,
							)?.order,
						};

						const image_gallery_res =
							await CreateProjectGalleryItem(data);
						if (image_gallery_res && image_gallery_res.data) {
							// upsert in project images(instead base 64 img put uploaded file):

							let founed_rec = updated_proj.gallery.find(
								(img) => img.prev_id === image.id,
							);
							if (founed_rec) {
								//replace
								const rec_index =
									updated_proj.gallery.indexOf(founed_rec);
								updated_proj.gallery[rec_index] =
									image_gallery_res.data;
							}
						}
					}
				}
				setImageFiles([]);
			}

			//upsert img order
			for (let i = 0; i < updated_proj.gallery.length; i++) {
				const updated_gallery_img_res = await UpdateGalleryImage(
					updated_proj.gallery[i],
				);
				// console.log(updated_gallery_img_res);
			}

			//delete removed imgs from project gallery
			if (imgsToDelete.length > 0) {
				for (let i = 0; i < imgsToDelete.length; i++) {
					const delete_img_res = await DeleteProjectImageFromGallery(
						imgsToDelete[i],
					);
				}
			}
			setIsLoading(false);
			handleOpenModal();
			handleGetProject();
		}
	};

	const handleShowImgs = (updated_files) => {
		let tmp_imgs = [...projectImgs];
		let tmp_layout = [...layout];
		for (let i = 0; i < updated_files.length; i++) {
			const el = updated_files[i];
			let file = el.file;
			let reader = new FileReader();
			reader.onloadend = function () {
				//projectImgs.img_url
				let already_uploaded_obj = projectImgs.find(
					(img) =>
						img.img_url === reader.result && img.prev_id === el.id,
				);
				if (already_uploaded_obj) return;

				//finding max order.i elem to create new i with biggest value
				let max_i = tmp_imgs.reduce((prev, current) => {
					let current_i = parseInt(current.order.i);
					return current_i > prev ? current_i : prev;
				}, 0);

				let new_obj = {
					id: max_i + 1,
					img_url: reader.result,
					is_main: el.is_main,
					prev_id: el.id,
				};

				let new_grid_layout;

				if (i < 3) {
					new_grid_layout = {
						i: `${max_i + 1}`,
						x: i,
						y: 0,
						w: 1,
						h: 1,
					};
				} else if (i >= 3 && i < 5) {
					new_grid_layout = {
						i: `${max_i + 1}`,
						x: i - 3,
						y: 1,
						w: 1,
						h: 1,
					};
				} else if (i >= 5 && i < 7) {
					new_grid_layout = {
						i: `${max_i + 1}`,
						x: i - 5,
						y: 2,
						w: 1,
						h: 1,
					};
				} else {
					new_grid_layout = {
						i: `${max_i + 1}`,
						x: i - 7,
						y: 3,
						w: 1,
						h: 1,
					};
				}
				new_obj.order = new_grid_layout;
				tmp_layout.push(new_grid_layout);
				tmp_imgs.push(new_obj);
				if (i + 1 === updated_files.length) {
					setProjectImgs([...tmp_imgs]);
					setLayout([...tmp_layout]);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const getPxFromVw = () => {
		const windowWidth = screenSize.current;
		return (windowWidth * 30) / 100; //50vw
	};

	const getPxFromVh = () => {
		const containerWidth = getPxFromVw();
		return containerWidth / 4;
	};

	const handleOnMoveCard = (new_layout) => {
		const prev_imgs = [...projectImgs];
		const prev_img_files = [...imageFiles];

		new_layout.map((item) => {
			let founded_item = prev_imgs.find((img) => img.order.i === item.i);
			if (founded_item) {
				founded_item.order = {
					i: `${item.i}`,
					x: item.x,
					y: item.y,
					w: item.w,
					h: item.h,
				};
			}
			let founded_file = prev_img_files.find(
				(img) => img.order.i === item.i,
			);
			if (founded_file) {
				founded_file.order = {
					i: `${item.i}`,
					x: item.x,
					y: item.y,
					w: item.w,
					h: item.h,
				};
			}
		});

		setProjectImgs(prev_imgs);
		setImageFiles(prev_img_files);
		setLayout(new_layout);
	};

	const handleSetMainImage = (img_index) => {
		setProjectImgs((prev) => [
			...prev.map((img, index) => {
				img.is_main = img_index === index ? true : false;
				return img;
			}),
		]);

		setImageFiles((prev) => [
			...prev.map((file, index) => {
				file.is_main = img_index === index ? true : false;
				return file;
			}),
		]);
	};

	const handleGoToNextStep = () => {
		const tmp_project = { ...project };
		if (currentStep === 1 && tmp_project.project_title.length === 0)
			return alertPopup('Please enter project title', 'error');
		if (currentStep === 2 && activeCats.length === 0)
			return alertPopup('Please choose at least one category', 'error');
		setCurrentStep(currentStep + 1);
	};

	useEffect(() => {
		if (Object.keys(currentProject).length > 0) {
			setProject({
				project_title: currentProject?.project_title
					? currentProject.project_title
					: '',
				project_subtitle: currentProject?.project_subtitle
					? currentProject.project_subtitle
					: '',
				project_description: currentProject?.project_description
					? currentProject.project_description
					: '',
			});
			setActiveCats(
				currentProject?.categories
					? currentProject?.categories?.map((cat) => {
							return cat.id;
					  })
					: [],
			);
			setProjectImgs(
				currentProject?.gallery ? currentProject?.gallery : [],
			);
			setLayout(
				currentProject?.gallery
					? currentProject?.gallery?.map((img) => {
							return img.order;
					  })
					: [],
			);
			setProjectMainIcon(
				currentProject?.project_icon
					? currentProject?.project_icon
					: {},
			);
		}
	}, [currentProject]);

	return (
		<ProjectContainer>
			{isLoading && <Loader />}
			{!isLoading && (
				<>
					{currentStep === 1 && (
						<>
							<InputWrapper>
								<Input
									defaultValue={project?.project_title}
									onChange={(e) =>
										handleChangeProjectData(
											'project_title',
											e.target.value,
										)
									}
									placeholder={'Enter Project Title'}
								/>
							</InputWrapper>
							<InputWrapper width={'70%'}>
								<Textarea
									rows={8}
									defaultValue={project?.project_description}
									onChange={(e) =>
										handleChangeProjectData(
											'project_description',
											e.target.value,
										)
									}
									placeholder={'Enter Description'}
								/>
							</InputWrapper>
							<InputWrapper width={'40%'}>
								<Input
									defaultValue={project?.project_subtitle}
									onChange={(e) =>
										handleChangeProjectData(
											'project_subtitle',
											e.target.value,
										)
									}
									placeholder={'Enter Project Sub Title'}
									sm
								/>
							</InputWrapper>
							<div className='btns flex justify-center'>
								{/* when editing project show its icon */}
								{projectMainIcon?.originalname && (
									<div className='flex icon-wrapper'>
										<LazyLoadImage
											alt={projectMainIcon?.originalname} 
											src={`${
												SERVER_ENDPOINT +
												projectMainIcon?.file_url
											}`}
										/>
									</div>
								)}
								<ContainedButton
									// onClick={()=>setProjectIcon(e)}
									className={'prev-btn relative'}
									secondary
								>
									<Input
										type='file'
										name='file'
										onChange={handleSetProjectIcon}
										accept='image/*'
										className={'upload-file-input'}
									/>
									{projectMainIcon?.name ? (
										<>
											<DoneIcon />
											<span>
												{' '}
												{projectMainIcon?.name}{' '}
											</span>
										</>
									) : (
										<>
											<UploadIcon />
											<span> UPLOAD PROJECT ICON </span>
										</>
									)}
								</ContainedButton>
								<ContainedButton
									primary
									onClick={() => handleGoToNextStep()}
									className={'next-btn'}
								>
									<span>NEXT</span>
									<NextIcon />
								</ContainedButton>
							</div>
						</>
					)}
					{currentStep === 2 && (
						<>
							<h4>Categories</h4>
							<CategoriesWrapper>
								{projectsCategories?.length > 0 &&
									projectsCategories.map((cat) => (
										<CategoryBlock
											key={cat.id}
											className={
												activeCats?.includes(cat.id) &&
												'active'
											}
											onClick={() => {
												handleAddCategory(cat.id);
											}}
										>
											{cat.category_name}
										</CategoryBlock>
									))}
							</CategoriesWrapper>
							{projectImgs?.length > 0 && type === 'edit' && (
								<>
									<h4>Project Gallery</h4>
									<ImagesContainerLg className='flex justify-between'>
										{projectImgs.map((img) => (
											<GridItem
												className='grid-item relative'
												style={{ aspectRatio: 1 }}
											>
												<div className='img-wrapper'>
													<LazyLoadImage
														alt='theguy'
														src={`${
															img?.img_url?.includes(
																'base64',
															)
																? img?.img_url
																: SERVER_ENDPOINT +
																  img?.img_url
														}`}						
													/>
												</div>
												<div className='btn-wrapper'>
													<EditBtn
														onClick={() => {
															handleRemoveImgFromGallery(
																img.id,
															);
														}}
														className='edit-proj'
														relative
														light
														fullwidth
													>
														<DeleteIcon />
														REMOVE
													</EditBtn>
												</div>
											</GridItem>
										))}
									</ImagesContainerLg>
								</>
							)}
							<UploadContainer
								className='flex-column align-center'
								style={{ width: '70%' }}
							>
								<ContainedButton
									variant='contained'
									className={isUploadVisible && 'bordered'}
									onClick={() =>
										setIsUploadVisible(!isUploadVisible)
									}
									secondary
									fullWidth
								>
									UPLOAD NEW IMAGES
								</ContainedButton>
								<div
									className={`dropzone-container ${
										isUploadVisible ? 'visible' : 'hidden'
									}`}
								>
									<Dropzone
										label={'Drop your files here'}
										onChange={handleOnChangeFile}
										value={imageFiles}
										maxFileSize={7998000}
										accept={'image/*'}
									>
										{imageFiles.map((file) => (
											<FileItem
												key={file.id}
												{...file}
												onDelete={handleOnDeleteFile}
												preview
												info
												hd
											/>
										))}
									</Dropzone>
								</div>
							</UploadContainer>
							<div className='btns flex justify-center'>
								<ContainedButton
									onClick={() =>
										setCurrentStep(currentStep - 1)
									}
									className={'prev-btn'}
									secondary
								>
									<BackIcon />
									<span>PREV</span>
								</ContainedButton>
								<ContainedButton
									primary
									onClick={() => handleGoToNextStep()}
									className={'next-btn'}
								>
									<span>NEXT</span>
									<NextIcon />
								</ContainedButton>
							</div>
						</>
					)}
					{currentStep === 3 && (
						<>
							{projectImgs.length > 0 && layout.length > 0 && (
								<ImagesContainerSm>
									<GridLayout
										className='layout'
										layout={layout}
										cols={3}
										rows={6}
										isDragable={true}
										isResizable={true}
										onDragStop={(e) => handleOnMoveCard(e)}
										onResizeStop={(e) =>
											handleOnMoveCard(e)
										}
										rowHeight={getPxFromVh()}
										width={getPxFromVw()}
									>
										{projectImgs.map((img_obj, key) => (
											<GridItem
												key={`${img_obj?.order?.i}`}
												className='grid-item'
											>
												<div className='img-wrapper'>
													<LazyLoadImage
														alt='theguy'
														src={`${
															img_obj?.img_url?.includes(
																'base64',
															)
																? img_obj?.img_url
																: SERVER_ENDPOINT +
																  img_obj?.img_url
														}`}					
													/>
												</div>
												<div className='text-wrapper'>
													<input
														onChange={() =>
															handleSetMainImage(
																key,
															)
														}
														type='radio'
														id='is_default'
														name='is_default'
														value='is_default'
														defaultChecked={
															img_obj.is_main
														}
													/>
													<label for='is_default'>
														Default
													</label>
												</div>
											</GridItem>
										))}
									</GridLayout>
								</ImagesContainerSm>
							)}
							<div className='btns flex justify-center'>
								<ContainedButton
									onClick={() =>
										setCurrentStep(currentStep - 1)
									}
									className={'prev-btn'}
									secondary
								>
									<BackIcon />
									<span>BACK</span>
								</ContainedButton>
								<ContainedButton
									onClick={() =>
										type === 'edit' 
											? handleUpdateProject()
											: handleCreateProject()
									}
									primary
								>
									SAVE
								</ContainedButton>
							</div>
						</>
					)}
				</>
			)}
		</ProjectContainer>
	);
}

export default ProjectEdit;
