import React, { useState, useEffect } from 'react';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleError = (event) => {
      setHasError(true);
      setError(event.error);
      console.error('Error caught by boundary:', event.error);
      if (event.error && event.error.stack) {
        console.error('Component stack:', event.error.stack);
      }
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', (event) => {
      if (event.reason instanceof Error) {
        setHasError(true);
        setError(event.reason);
        console.error('Unhandled rejection caught:', event.reason);
      }
    });

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', () => {});
    };
  }, []);

  if (hasError) {
    // You can customize this message or display any UI to indicate an error
    return <h1>Something went wrong. Please try again later.</h1>;
  }

  return <>{children}</>;
};

export default ErrorBoundary;